<template>
  <div>
    <AppTable
      :rows="currency.value"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: currency.limit,
        count: currency.count,
        page: currency.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #default="{ column, row, formattedRow }">
        <!-- Column: Name -->
        <span v-if="column.field === 'name'"> {{ row.flag }} {{ row.name }} </span>

        <!-- Column: Action -->
        <span v-else-if="column.field === 'action'">
          <b-button variant="icon" @click="edit(row.id)">
            <feather-icon icon="Edit2Icon" class="mr-50" />
          </b-button>
        </span>

        <!-- Column: Icon -->
        <span v-else-if="column.field === 'image'">
          <img :src="row.image" class="currency-icon" />
        </span>

        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

import columns from './config/tableConfig'
import AppTable from '@/components/AppTable.vue'

export default {
  name: 'CurrencyFiatView',
  components: {
    AppTable,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,
    }
  },

  computed: {
    ...mapGetters({
      currency: 'fiatCurrency/fiatCurrency',
    }),

    direction() {
      return store.state.appConfig.isRTL
    },
  },
  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchFiatCurrency: 'fiatCurrency/fetchFiatCurrency',
    }),

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })

      this.getCurrency(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getCurrency(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getCurrency(query)
    },

    createQueryConfig(config) {
      return {
        page: this.currency.page,
        limit: this.currency.limit,

        ...config,
      }
    },

    getCurrency(query = {}) {
      return this.waitRequest(() => {
        return this.fetchFiatCurrency(query).catch(this.checkErrorsAlert)
      })
    },

    edit(id) {
      this.$router.push({ path: `/currency-fiat/edit/${id}` })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.currency-icon {
  width: 2rem;
}
</style>
