var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppTable',{attrs:{"rows":_vm.currency.value,"columns":_vm.columns,"is-loading":_vm.requestInProgress,"pagination":{
      limit: _vm.currency.limit,
      count: _vm.currency.count,
      page: _vm.currency.page,
    }},on:{"change-limit":_vm.changePageLimit,"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var column = ref.column;
    var row = ref.row;
    var formattedRow = ref.formattedRow;
return [(column.field === 'name')?_c('span',[_vm._v(" "+_vm._s(row.flag)+" "+_vm._s(row.name)+" ")]):(column.field === 'action')?_c('span',[_c('b-button',{attrs:{"variant":"icon"},on:{"click":function($event){return _vm.edit(row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}})],1)],1):(column.field === 'image')?_c('span',[_c('img',{staticClass:"currency-icon",attrs:{"src":row.image}})]):_c('span',{staticClass:"flex-center-align"},[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }