export default [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'Name',
    field: 'name',
  },
  // {
  //   label: 'Network',
  //   field: 'network',
  // },
  {
    label: 'Symbol',
    field: 'symbol',
  },
  // {
  //   label: 'CoinMarketCapId',
  //   field: 'cmcId',
  // },
  {
    label: 'Icon',
    field: 'image',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
